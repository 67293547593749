import React from 'react'
import PackagesLayout from './PackagesLayout'
import playIcon from '../../assets/img/play-icon.svg'
import tickIcon from '../../assets/img/tick-icon.svg'

const PackageDes = ({ pkg }) => {
    const { isRecomended, title, price, crncyIcon, description, detailList } = pkg
    return (
        <div className='col-12 col-md-6 col-lg-4 '>
            <div className={`px-3 packageBorder position-relative ${isRecomended ? 'isRecomended' : ''}`}>
                {!!isRecomended && <div className='bg-primary font-space fs-24 recomend-pck-tag text-white ps-3'>Recommended</div>}
                <PackagesLayout>
                    <div className='py-5'>
                        <div className='py-5'>
                            <div className='py-5'>
                                <div className="p-4">
                                    <img src={playIcon} alt='.' />
                                    <p className='font-space fs-24 text-white mt-3'>{title}</p>
                                    <p className='font-space fs-40 text-white mt-3'>{crncyIcon + ' ' + price} </p>
                                    <p className='font-poppins fs-14 text-white mt-3'>{description} </p>
                                    {isRecomended ? (
                                        <button class="image-bg-button white-button text-blue text-nowrap px-5 py-3 font-space w-100 bg-transparent border-0 text-center fs-18 my-4 d-inline-block">Get Started</button>
                                    ) : (
                                        <button class="image-bg-button blue-button text-white text-nowrap px-5 py-3 font-space w-100 bg-transparent border-0 text-center fs-18 my-4 d-inline-block">Get Started</button>

                                    )}
                                    <p className='font-poppins fs-16 fw-700 text-white mt-3' >What’s Included</p>
                                    <div className='pb-5'>
                                        {detailList.map(each => (
                                            <div key={each.id} className='d-flex gap-2 mb-2' >
                                                <img src={tickIcon} className='mt-1 align-self-baseline ' alt='Avail' />
                                                <p className='mb-0 text-white font-poppins fs-12' >{each.detail}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </PackagesLayout>
            </div>
        </div>
    )
}

export default PackageDes