import React from 'react'
import HeadSection from '../ContactUs/HeadSection'
import Topbar from '../../Components/Topbar'
import PackageDes from '../../Components/Packages/PackageDes'

const Packages = () => {

    const packagesList = [
        {
            id: 1,
            isRecomended: false,
            title: 'Basic',
            price: '19',
            crncyIcon: '$',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.',
            detailList: [
                {
                    id: '1',
                    available: false,
                    detail: 'Sed ut perspiciatis unde omnis iste natus.'
                },
                {
                    id: '2',
                    available: false,
                    detail: 'Perspiciatis unde omnis iste.'
                },
                {
                    id: '3',
                    available: false,
                    detail: 'Ut spiciatis unde omnis.'
                },
                {
                    id: '4',
                    available: false,
                    detail: 'Ande omnis iste natus.'
                },
                {
                    id: '5',
                    available: false,
                    detail: 'Rspiciatis unde omnis iste.'
                },
                {
                    id: '6',
                    available: false,
                    detail: 'Sed ut perspiciatis unde omnis iste natus.'
                },
            ]
        },
        {
            id: 2,
            isRecomended: true,
            title: 'standard',
            price: '19',
            crncyIcon: '$',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.',
            detailList: [
                {
                    id: '1',
                    available: false,
                    detail: 'Sed ut perspiciatis unde omnis iste natus.'
                },
                {
                    id: '2',
                    available: false,
                    detail: 'Perspiciatis unde omnis iste.'
                },
                {
                    id: '3',
                    available: false,
                    detail: 'Ut spiciatis unde omnis.'
                },
                {
                    id: '4',
                    available: false,
                    detail: 'Ande omnis iste natus.'
                },
                {
                    id: '5',
                    available: false,
                    detail: 'Rspiciatis unde omnis iste.'
                },
                {
                    id: '6',
                    available: false,
                    detail: 'Sed ut perspiciatis unde omnis iste natus.'
                },
            ]
        },
        {
            id: 3,
            isRecomended: false,
            title: 'Basic',
            price: '19',
            crncyIcon: '$',
            description: 'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.',
            detailList: [
                {
                    id: '1',
                    available: false,
                    detail: 'Sed ut perspiciatis unde omnis iste natus.'
                },
                {
                    id: '2',
                    available: false,
                    detail: 'Perspiciatis unde omnis iste.'
                },
                {
                    id: '3',
                    available: false,
                    detail: 'Ut spiciatis unde omnis.'
                },
                {
                    id: '4',
                    available: false,
                    detail: 'Ande omnis iste natus.'
                },
                {
                    id: '5',
                    available: false,
                    detail: 'Rspiciatis unde omnis iste.'
                },
                {
                    id: '6',
                    available: false,
                    detail: 'Sed ut perspiciatis unde omnis iste natus.'
                },
            ]
        },
    ]

    return (
        <div className="main-bg">
            <div className="top-bg">
                <Topbar />
                <div className="container">
                    <HeadSection />
                </div>
            </div>
            <div className='container'>
                <p className=" fs-7vw text-center text-white mx-auto w-18ch mt-4" >Online Booking</p>
                <div className='row'>
                    {packagesList.map(each => (
                        <PackageDes key={each.id} pkg={each} />
                    ))}
                </div>
            </div>
        </div>

    )
}

export default Packages